import {
  ref, watch, onUnmounted,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableBed, isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import reportPropertyModule from '../reportPropertyModule';

export default function useReportNearEmptyRoom() {
  const ROOM_STORE_MODULE_NAME = 'report-near-empty-room';

  // Register module
  if (!store.hasModule(ROOM_STORE_MODULE_NAME)) {
    store.registerModule(ROOM_STORE_MODULE_NAME, reportPropertyModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(ROOM_STORE_MODULE_NAME)) {
      store.unregisterModule(ROOM_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const { t } = useI18nUtils();
  const rows = ref([]);
  // filter
  const apartment = ref(null);
  const location = ref(null);
  const floor = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartment.name',
    },
    {
      label: t('Tên phòng'),
      field: 'room.name',
    },
    {
      label: t('Tên giường'),
      field: 'bed.name',
      hidden: !isEnableBed()
    },
    // {
    //   label: 'Giá thuê',
    //   field: 'price',
    //   type: 'number',
    //   width: '140px',
    // },
    // {
    //   label: 'Đặt cọc',
    //   field: 'deposit',
    //   type: 'number',
    //   width: '140px',
    // },
    // {
    //   label: 'Diện tích',
    //   field: 'room.size',
    //   type: 'number',
    //   width: '100px',
    // },
    {
      label: t('Ngày trống'),
      field: 'emptyDate',
      width: '100px',
    },

  ];

  //   API Call
  const fetchRooms = () => {
    isLoading.value = true;
    store
      .dispatch('report-near-empty-room/fetchNearEmptyRooms', serverParams.value)
      .then(response => {
        totalRecords.value = response.data.length;
        rows.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const fetchData = () => {
    fetchRooms();
  };

  const exportData = () => {
    store
      .dispatch('report-near-empty-room/exportNearEmptyRooms', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(floor, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.floorId = val.id;
    } else {
      delete filter.floorId;
    }
    updateParams({ filter });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    floor,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    fetchRooms,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    exportData,
    t,
  };
}
